import { watch } from 'fs';
<template>
  <div class="menu">
    <div class="actionBar">
      <a-button
        type="primary"
        @click="showModal"
        v-if="handLists.top.indexOf('add') != -1"
      >
        新增栏目
      </a-button>
    </div>

    <!-- 添加新菜单 -->
    <a-modal
      :title="modalTitle"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确定"
      cancelText="取消"
      :width="840"
    >
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        :from="addFromData"
      >
        <a-row>
          <a-col :span="12">
            <a-form-item label="父级菜单">
              <a-tree-select
                v-model="addFromData.parent_id"
                style="width: 100%"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="addMenu"
                placeholder="选择父级菜单"
                :replaceFields="{ value: 'id', title: 'name' }"
                allowClear
              >
                <!-- :replaceFields="{value:id}" -->
              </a-tree-select>
            </a-form-item>
            <a-form-item label="栏目名称">
              <a-input v-model="addFromData.name" />
            </a-form-item>
            <a-form-item label="栏目简介">
              <a-input v-model="addFromData.post_content" />
            </a-form-item>
            <a-form-item label="所属科室">
              <DepartmentSelect
                @DepartmentSelect="DepartmentSelect"
                :selectId="addFromData.department_id"
              ></DepartmentSelect>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="排序">
              <a-input-number :min="0" v-model="addFromData.list_order" />
            </a-form-item>

            <a-form-item label="状态">
              <a-radio-group v-model="addFromData.is_show">
                <a-radio :value="1">
                  启用
                </a-radio>
                <a-radio :value="0">
                  禁用
                </a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="推荐">
              <a-radio-group v-model="addFromData.recommended">
                <a-radio :value="1">
                  是
                </a-radio>
                <a-radio :value="0">
                  否
                </a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="背景图">
              <a-upload
                name="file"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :action="uploadImage"
                :before-upload="beforeUpload"
                @change="handleChange"
              >
                <img
                  v-if="imageUrl"
                  :src="imageUrl"
                  style="display:block;max-width:200px;max-height:200px"
                />
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>

    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      :pagination="false"
    >
      <span slot="list_order" slot-scope="text, record">
        <a-input-number
          v-model="record.list_order"
          style="width:80px"
          @blur="listOrderChangeVal(record)"
          :min="0"
          @focus="saveOldNum(text)"
        >
        </a-input-number>
      </span>

      <span slot="collect_count" slot-scope="text, record">
        <a-input
          v-model="record.collect_count"
          style="width:80px"
          v-if="record.parent_id != 0"
          @blur="collectCountChangeVal(record)"
        >
        </a-input>
      </span>

      <span slot="is_show" slot-scope="text, record">
        <a-tag :color="text == 1 ? 'green' : 'orange'">
          {{ text == 1 ? "启用" : "禁用" }}
        </a-tag>

        <a-tag :color="record.recommended == 1 ? 'green' : 'orange'">
          {{ record.recommended == 1 ? " 推荐" : "不推荐" }}
        </a-tag>
      </span>

      <span slot="action" slot-scope="record">
        <span>
          <FontIcon
            @click.native="addSubMenu(record)"
            title="添加子菜单"
            type="icon-fenzhi"
            size="22"
            v-if="
              record.parent_id == 0 && handLists.right.indexOf('addSub') != -1
            "
          ></FontIcon>
          <a-divider type="vertical" />
        </span>

        <span v-if="handLists.right.indexOf('edit') != -1">
          <FontIcon
            @click.native="editMenu(record)"
            title="修改"
            type="icon-xiugai"
            size="22"
          ></FontIcon>

          <a-divider type="vertical" />
        </span>


        <a-popconfirm
          title="确定删除？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="confirm(record)"
          v-if="handLists.right.indexOf('del') != -1"
        >
          <FontIcon title="删除" type="icon-lajitong" size="22"></FontIcon>
        </a-popconfirm>

        <span v-if="record.parent_id != 0" >
             <a-divider type="vertical" />
        <FontIcon
        
          @click.native="copyLink(record.share_url)"
          title="点击复制链接"
          type="icon-lianjiefenxiang"
          size="22"
        ></FontIcon>
        </span>
     
      </span>
    </a-table>
  </div>
</template>

<script>
import {
  groupIndex,
  groupShowList,
  groupDataSave,
  groupDelete,
  groupChangeVal,
  groupChangeCollect,
  uploadImage,
} from "@/apis/index";

const columns = [
  {
    align: "center",
    title: "排序",
    dataIndex: "list_order",
    scopedSlots: { customRender: "list_order" },
    width: 180,
  },
  {
    align: "center",
    title: "栏目ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
    width: 100,
  },
  {
    align: "center",
    title: "栏目名称",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
    // width: 150,
  },
  {
    align: "center",
    title: "关注数",
    dataIndex: "collect_count",
    scopedSlots: { customRender: "collect_count" },
  },
  {
    title: "所属科室",
    dataIndex: "department",
    scopedSlots: { customRender: "department" },
    customRender: (text) => {
      if (text) {
        return text.name;
      } else {
        return "";
      }
    },
  },
  {
    title: "栏目简介",
    dataIndex: "post_content",
    scopedSlots: { customRender: "post_content" },
    width: 400,
  },
  {
    title: "状态",
    dataIndex: "is_show",
    scopedSlots: { customRender: "is_show" },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {
  data() {
    return {
      uploadImage,
      columns,
      data: [],
      visible: false,
      confirmLoading: false,

      modalTitle: "",
      addFromData: {},
      addMenu: this.$store.state.groupShowList,
      // DepartmentShowList: this.$store.state.departmentShowList,
      loading: false,
      imageUrl: "",

      oldNum: "",
      handLists: this.$store.state.menuHandleList,
    };
  },

  created() {
    this.getGroupIndex();
  },
  computed: {
    groupShowList() {
      return this.$store.state.groupShowList;
    },
    handList() {
      return this.$store.state.menuHandleList;
    },
  },
  watch: {
    groupShowList(val) {
      this.addMenu = val;
    },
     handList(val) {
      this.handLists.top = val.top ? val.top : [];
      this.handLists.right = val.right ? val.right : [];
    },
  },
  methods: {
    copyLink(data) {
      let OrderNumber = data;
      let newInput = document.createElement("input");
      newInput.value = OrderNumber;
      document.body.appendChild(newInput);
      newInput.select();
      document.execCommand("Copy");
      newInput.remove();
      this.$message.success("复制成功");
    },
    saveOldNum(text) {
      this.oldNum = text;
    },
    listOrderChangeVal(record) {
      if (this.oldNum == record.list_order) {
        return false;
      } else {
        groupChangeVal({
          id: record.id,
          change_value: record.list_order,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success("修改成功");
            this.getGroupIndex();
          }
        });
      }
    },
    collectCountChangeVal(record) {
      groupChangeCollect({
        id: record.id,
        collect_count: record.collect_count,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success("修改成功");
        }
      });
    },

    getGroupShowList() {
      groupShowList().then((res) => {
        this.$store.commit("setGroupShowList", res.data);
      });
    },

    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl;
          this.loading = false;
        });
        this.addFromData.cover = info.file.response.data.id;
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    confirm(rec) {
      groupDelete({ id: rec.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.getGroupShowList();
          this.getGroupIndex();
        }
      });
    },
    // menuSelect(a, b, c) {},
    getGroupIndex() {
      groupIndex().then((res) => {
        this.data = res.data;
      });
    },
    showModal() {
      this.modalTitle = "新增栏目";
      this.imageUrl = "";
      (this.addFromData = {
        id: "",
        name: "",
        department_id: undefined,
        post_content: "",
        parent_id: undefined,
        cover: "",
        list_order: "",
        is_show: 1,
        recommended: 1,
      }),
        (this.visible = true);
    },
    handleOk() {
      this.confirmLoading = true;
      groupDataSave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.$message.success("保存成功");
          this.getGroupIndex();
          this.getGroupShowList();
          this.visible = false;
        }
        this.confirmLoading = false;
      });
    },
    handleCancel() {
      this.visible = false;
    },
    addSubMenu(record) {
      this.modalTitle = "添加子栏目";
      this.addFromData = {
        id: "",
        name: "",
        department_id: undefined,
        post_content: "",
        cover: "",
        list_order: "",
        is_show: 1,
        recommended: 1,
        parent_id: record.id,
      };
      this.visible = true;
    },
    editMenu(record) {
      this.imageUrl = record.cover_url;
      this.modalTitle = "修改栏目信息";
      this.addFromData = { ...record };
      if (this.addFromData.parent_id == 0) {
        this.addFromData.parent_id = undefined;
      }
      this.visible = true;
    },
    DepartmentSelect(val) {
      this.addFromData.department_id = val;
    },
  },
};
</script>

<style lang="less" scoped>
.menu {
  .actionBar {
    padding: 30px 0;
  }
}

.avatar-uploader > .ant-upload {
  width: 120px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
